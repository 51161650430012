<template>
  <div class="pad-t-2">
    <div class="bb-container bb-container--lista">
      <div
        class="ml-news ml-news--blue"
      >
        <div class="ml-news__picture">
          <div class="ml-news__label">
            <span class="badge badge--accent badge--sm"> ¡Novedades! </span>
          </div>
          <img
            alt=""
            loading="eager"
          />
        </div>

        <div class="ml-news__content">
          Loading...
        </div>
        <div class="ml-news__actions">
          <button class="button button--sm button--dark">Explorar</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BannerPlaceholder",
};
</script>
